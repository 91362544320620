import React from 'react'

function TagSelector(props) {
	return (
		<div className="tag-selector">
			{['Fighter', 'Mage', 'Tank', 'Assassin', 'Support'].map((tag) => (
				<label key={tag}>
					<input
						type="checkbox"
						value={tag}
						checked={props.selectedTags.includes(tag)} // Use the selectedTags prop here
						onChange={props.handleTagSelection}
					/>
					<span>{tag}</span>
				</label>
			))}
		</div>
	)
}

export default TagSelector;
