import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChampionCard from './ChampionCard';
import TagSelector from './TagSelector'; // Import the TagSelector component
import '../assets/scss/main.css'

const ChampionList = () => {
	const [champions, setChampions] = useState([]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [selectedTags, setSelectedTags] = useState([]); // Use a state variable to store the selected tags

	useEffect(() => {
		const fetchChampions = async () => {
			try {
				const championDataResponse = await axios.get(
					'http://ddragon.leagueoflegends.com/cdn/12.23.1/data/en_US/champion.json'
				);
				const championData = championDataResponse.data.data;
				const allChampions = Object.values(championData);
				setChampions(allChampions);
				setIsLoaded(true);
			} catch (err) {
				setError(err);
			}
		};
		fetchChampions();
	}, []);

	// Add a function to handle tag selection
	const handleTagSelection = (event) => {
		const tag = event.target.value;
		if (selectedTags.includes(tag)) {
			setSelectedTags(selectedTags.filter((t) => t !== tag));
		} else {
			setSelectedTags([...selectedTags, tag]);
		}
	};

	if (error) {
		return <p>An error occurred: {error.message}</p>;
	} else if (!isLoaded) {
		return <p>Loading...</p>;
	} else {
		return (
			<div className="container">
				{/* Render the TagSelector component and pass it the handleTagSelection function as a prop */}
				<TagSelector selectedTags={selectedTags} handleTagSelection={handleTagSelection} />
				<div className="champion-list">
					{/* Filter champions by selected tags and render a ChampionCard for each of them */}
					{champions
						.filter((champion) =>
							selectedTags.some((tag) => champion.tags.includes(tag))
						)
						.map((champion) => (
							<ChampionCard
								key={champion.id}
								name={champion.name}
								imageUrl={`https://ddragon.leagueoflegends.com/cdn/12.23.1/img/champion/${champion.id}.png`}
							/>
						))}
				</div>
			</div>
		);
	}
};

export default ChampionList;
