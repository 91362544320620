import React from "react";
import ChampionList from './Components/ChampionList'


const Champions = () => {
    return (
        <div className="Champions">
            <h1>Champions</h1>
            <div className='main-container'>
            </div>
            <ChampionList />
        </div>
    );
}


export default Champions;