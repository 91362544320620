import React from "react";

const SummonerSearch = () => {
	return (
		<div className="Summoner-Search">
			<h1>Summoner Search</h1>
			<div className='main-container'>
			</div>
		</div>
	);
}

export default SummonerSearch;
