import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SummonerSearch from './SummonerSearch';
import Champions from './Champions';
import Navbar from './Components/Navbar';
import './assets/scss/main.css'


function App() {
	return (
		<BrowserRouter>
			<div className="App">
				<Navbar />
				<Routes>
					<Route exact path="/champions" element={<Champions />} />
					<Route path="/summoner-search" element={<SummonerSearch />} />
				</Routes>
			</div>
		</BrowserRouter>
	);
}

export default App;
