import React from 'react'
import { Link } from 'react-router-dom';
import '../assets/scss/navbar.css'


const Navbar = () => {
    return (
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link to="/" className='navbar-logo'></Link>
                <div className='navbar-menu-button'>Menu</div>
                <ul className='navbar-nav'>
                    <li className='nav-item'><Link to="/summoner-search">Summoner Search</Link></li>
                    <li className='nav-item'><Link to="/champions">Champions</Link></li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar;